@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {
	.visual_sp {
		background-size: contain;
		height: 0;
		text-align: center;
		background-position: center center;
		background-attachment: fixed;
		padding-bottom: 133.33%;
		position:relative;
		margin-top: 60px;
		
		.vegas-overlay {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			height: 50%;
			background-size: contain;
			margin: 60px 30px 0;
		}
	}
	.contents {
		pading-top: 0;
		
		.inner {
			padding: 30px 15px 40px;
		}
		.ttl01 {
			padding-top: 0;
			height: auto;
			margin-bottom: 20px;
			&::before {
				display: none;
			}
		}
	}
	.bg-gray {
		background: none;
	}
	#index_contets {
		margin-top: 30px;
	}
	.index__concept {
		background: url(/common/img/common/bg_shishi_sp.png) no-repeat;
		background-size: contain;
	}
	.index__concept-img {
		margin-bottom: 30px;
		&__item {
			display: flex;
			align-items: center;
			justify-content: center;
			text-decoration: none;
			padding: 60px 0;
			
			&:hover {
				opacity: 0.7;
			}
			
			&--kurojishi {
				background:url(/common/img/index/pic_index_01_sp.png) no-repeat;
				background-size: cover;
			}
			&--nagai {
				background:url(/common/img/index/pic_index_02_sp.png) no-repeat;
				background-size: cover;
			}
			&--yamagata {
				background:url(/common/img/index/pic_index_03_sp.png) no-repeat;
				background-size: cover;
			}
			&__text {
				width: 130px;
				height: 130px;
				border: 1px solid $borderColor;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #e5e5e5;
				font-size: 2.4rem;
			}
		}
	}
	.news-list {
		margin: 0px;
		border-top: 1px solid #ced3e1;

		&__item {
			display: flex;
			margin-left: 0px;
			margin-right: 0px;
			width: 100%;
			align-items: center;
			border-bottom: 1px solid #ced3e1;
			
			&:hover {
				text-decoration: none;
				
			}

			&__date {
				flex: 0 0 auto;
				margin: 0 20px 0 0;
			}
			&__link {
				flex: 1 1 auto;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
	.index__sns {
		.index__instagram {	
			#instafeed {
				margin-top: 20px;
				display: flex;
				flex-wrap: wrap;
		
				li {
					width: calc( 100% / 3 );
					position: relative;
					cursor: pointer;
					overflow: hidden;
		
					img {
						width: 100%;
					}
		
					div {
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						padding: 20px;
						background: rgba($baseColor, 0.7);
						color: #fff;
						text-align: left;
						transform: translateY(100%);
						transition: all .35s ease;
						
						a {
							color: #fff;
						}
		
						.date {
							margin: 0 0 10px;
							font-weight: bold;
							line-height: 1;
							letter-spacing: 0.1em;
						}
						
						.caption {
							position: relative;
							height: calc(14px * 1.6 * 6);
							margin: 0;
							font-size: 1.2rem;
							line-height: 1.6;
							overflow: hidden;
						}
		
					}
					
					&:hover div {
						transform: translateY(0);
					}
				}
			}
		}
	}
}