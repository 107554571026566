@charset "utf-8";
/* ============================================================ */
/* 基本設定 */
/* ============================================================ */
html {
	overflow-y: scroll;
}

@media screen and (min-width:  767.5px) and (max-width:1024px) {
	html {
		font-size: calc(100vw / 102.4);
	}
}

body {
	color: $textColor;
	font-family: ヒラギノ明朝 W3 JIS2004, Hiragino Mincho W3 JIS2004, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", "serif";
	font-size: 1.5rem;
	letter-spacing: 0.05em;
	line-height: 1.8;
}

.sp-view {
	display: none !important;
}

a {
	color: $textColor;
	text-decoration: underline;
	@include transition;
	
	&:hover {
		text-decoration: none;
	}
}


/* --------------------------------------------------- */
/* topic-path */
/* --------------------------------------------------- */
.topic-path {
	margin: 0 0 50px;
	padding: 12px 0;
	border-bottom: 1px solid $borderColor;
	font-size: $xxs;
	line-height: 1.4;
	
	&__inner {
		display: flex;
		flex-wrap: wrap;
		max-width: 1000px;
		margin: 0 auto;
		padding: 0 20px;
	}
	&__item {
		
		&:not(:last-child) {
			&::after {
				margin: 0 10px;
				content: "\f105";
				font-family: 'icomoon';
			}
		}
	}
}


/* --------------------------------------------------- */
/* pagetop */
/* --------------------------------------------------- */
.pagetop {
	position: fixed;
	right: 50px;
	bottom: 50px;
	
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50px;
		height: 50px;
		background: rgba($baseColor, 0.9);
		color: $white;
		font-size: $xxxl;
		@include radius(4);
		@include transition;
		text-decoration:  none;
		
		&:hover {
			@include opacity;
		}
	}
}