@charset "utf-8";
/* ============================================================ */
/* about.html */
/* ============================================================ */
.about {
	.inner {
		padding: 50px 20px 60px;
	}
	.concept {
		&__item {
			display: flex;
			margin-top: 65px;
			min-height: 490px;
			&__text {
				background: rgba(255,255,255,0.9);
				width: 40%;
				padding: 40px 30px;
			}
			.ttl02 {
				font-size: 4.8rem;
			}
		}
		&--kurojishi {
			background: url(/common/img/about/bg_about_01.png) center top no-repeat;
			background-size: 1600px 550px;
		}
		&--nagai {
			background: url(/common/img/about/bg_about_02.png) center top no-repeat;
			background-size: 1600px 550px;
		}
		&--yamagata {
			background: url(/common/img/about/bg_about_03.png) center top no-repeat;
			background-size: 1600px 550px;
		}
	}
}