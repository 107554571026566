@charset "utf-8";

$baseColor: #adb4c3;
$textColor: #333333;
$borderColor: #808080;

$white: #fff;
$brown: #a85629;
$lightBrown: #faf5e7;


@mixin clearfix {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin radius($size) {
	border-radius: ($size + px);
}

@mixin circle($size){
	width: $size + px;
	height: $size + px;
	border-radius: 50%;
}

@mixin transition {
	transition: 0.3s all;
}

@mixin opacity($opacity: 0.7) {
	opacity: $opacity;
}

@mixin centering-elements($horizontal: true, $vertical: true) {
	position: absolute;
	@if $horizontal and $vertical {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	} @else if $horizontal {
		left: 50%;
		transform: translate(-50%, 0);
	} @else if $vertical {
		top: 50%;
		transform: translate(0, -50%);
	}
}

@mixin font-bold {
	font-family: ヒラギノ明朝 W6 JIS2004, Hiragino Mincho W6 JIS2004, "serif";
}
@mixin font-nomal {
	font-family: ヒラギノ明朝 W3 JIS2004, Hiragino Mincho W3 JIS2004, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", "serif";
}


/* font-size
-------------------------------------------------- */
$xxxs: 1.0rem;
$xxs: 1.2rem;
$xs: 1.3rem;
$s: 1.4rem;
$m: 1.6rem;
$l: 1.8rem;
$xl: 2.0rem;
$xxl: 2.2rem;
$xxxl: 2.4rem;
$xxxxl: 3.0rem;

