@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {
	font-size: 1.6rem;
	
	header {
		.header-logo {
			display: none;
		}
		.gnav {
			&__item {
				color: #e5e5e5;
				&__text {
					&__en {
						color: #989898;
					}
				}
				&::after {
					background: $white;
				}
			}
			&__icon {
				color: $white;
			}
		}
	}
	
	.clone-header {
		.header-logo {
			width: 170px;
			margin-right: auto;
			display: block!important;
			margin-bottom: 10px;
		}
		.gnav {
			&__item {
				color: $textColor;
				&::after {
					background: #8590a4;
				}
			}
			&__text {
				&__en {
					color: #8590a4;
				}
			}
			&__icon {
				color:#8590a4;
			}
		}
	}
	
	.contents {
		pading-top: 0;
		
		.inner {
			padding: 50px 20px 60px;
		}
		.ttl01 {
			&::before {
				display: none;
			}
		}
	}
	.wrap {
		display: flex;
		flex-direction: column;
		height: 100vh;
	}	
	
	.visual_pc {
		background-size: auto 770px;
		min-height: 770px;
		text-align: center;
		background-position: center center;
		background-attachment: fixed;
		position:relative;
		
		.vegas-overlay {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 794px;
			height: 262px;
			background-size: contain;
			margin: 0 auto;
		}
		.scroll {
			position:  absolute;
			bottom: 30px;
			width: 25px;
		}
	}
	
	#index_contets {
		margin-top:-94px;
		padding-top:94px;
	}
	.index__concept {
		.inner {
			position: relative;
			margin-bottom: 220px;
			&::before {
				background: url(/common/img/common/bg_shishi.png);
				background-size: 785px 367px;
				position: absolute;
				content: '';
				display: block;
				width: 785px;
				height: 367px;
				left: -160px;
				top: 50%;
				margin-top: -100px;
			}
		}
	}
	.index__concept-img {
		position: relative;
		top: -270px;
		margin-bottom: -230px;
		.column3 {
			margin-left: -5px;
			margin-right: -5px;
			justify-content: space-between;
		}
		&__item {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 370px;
			margin-left: 5px;
			margin-right: 5px;
			text-decoration: none;
			
			&:hover {
				opacity: 0.7;
			}
			
			&--kurojishi {
				background:url(/common/img/index/pic_index_01.png);
				background-size: 330px 370px;
			}
			&--nagai {
				background:url(/common/img/index/pic_index_02.png);
				background-size: 330px 370px;
			}
			&--yamagata {
				background:url(/common/img/index/pic_index_03.png);
				background-size: 330px 370px;
			}
			&__text {
				width: 130px;
				height: 130px;
				border: 1px solid $borderColor;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #e5e5e5;
				font-size: 2.4rem;
			}
		}
	}
	.news-list {
		margin: 0 0 40px;
		border-top: 1px solid $borderColor;

		&__item {
			display: flex;
			margin-left: 0px;
			margin-right: 0px;
			width: 100%;
			align-items: center;
			border-bottom: 1px solid $borderColor;
			
			&:hover {
				text-decoration: none;
				
			}

			&__date {
				flex: 0 0 auto;
				margin: 0 20px 0 0;
			}
			&__link {
				flex: 1 1 auto;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
	.index__sns {
		.index__instagram {	
			#instafeed {
				display: flex;
		
				li {
					flex: 1 1 200px;
					position: relative;
					cursor: pointer;
					overflow: hidden;
					max-width: 400px;
					max-height: 400px;
					margin: 0 3px;
					
					&:first-child {
						margin-left: 0;
					}
					&:last-child {
						margin-right: 0;
					}
		
					img {
						width: 100%;
					}
		
					div {
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						padding: 20px;
						background: rgba(45,48,62, 0.9);
						color: #fff;
						text-align: left;
						transform: translateY(100%);
						transition: all .35s ease;
						
						a {
							color: #fff;
						}
		
						.date {
							margin: 0 0 10px;
							font-weight: bold;
							line-height: 1;
							letter-spacing: 0.1em;
						}
						
						.caption {
							position: relative;
							height: calc(14px * 1.6 * 6);
							margin: 0;
							font-size: 1.2rem;
							line-height: 1.6;
							overflow: hidden;
						}
		
					}
					
					&:hover div {
						transform: translateY(0);
					}
				}
			}
		}
	}
}