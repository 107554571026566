@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
footer {
	border-top: 1px solid $borderColor;
	text-align: center;
	line-height: 1.6;
	background: #2d303e;

	.footer-logo {
		margin: 40px 0;
		
		&__link {
			display: block;
			width: 155px;
			margin: 0 auto 15px;
		}
		&__address {
			color: #ced3e1;
			font-size: $xs;
			margin-bottom: 20px;
		}
		&__icon {
			color: #ced3e1;
			text-decoration: none;
			font-size: 3.0rem;
			margin: 0 10px;
			&:hover {
				opacity: 0.7;
			}
		}
		&__sitemap {
			margin-top: 20px;
			font-size: $xs;
			display: flex;
			justify-content: center;
			&__item {
				padding: 0 10px;
				line-height: 100%;
				border-right: 1px solid #ced3e1;
				&:first-child {
					border-left: 1px solid #ced3e1;
				}
				&__link {
					color: #ced3e1;		
					text-decoration: none;
					&:hover {
						opacity: 0.7;
					}
				}
			}
		}
	}

	.copyright {
		padding: 20px 0;
		color: #ced3e1;
		font-size: $xxs;
		background: #2d303e;
		border-top: 1px solid #4e5366;
	}
}