@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
noscript {
	position: absolute;
	top: 96px;
	width: 100%;
}
header {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	width: 100%;
	border-bottom: 1px solid $borderColor;
	
	.header-wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 1040px;
		margin: 5px auto 0px;
		padding: 0 20px;
	}
	.header-logo {
		width: 170px;
		margin-right: auto;
		display: block;
		margin-bottom: 10px;
	}
	.gnav {
		max-width: 1040px;
		padding: 0 20px;
		
		&__inner {
			position: relative;
			display: flex;
			
			&::before {
				position: absolute;
				top: 5px;
				right: 0;
				content: '';
				height: 15px;
			}
		}
		
		&__item {
			flex: 1 1 auto;
			position: relative;
			padding: 25px 17px 30px;
			color: $textColor;
			font-size: 1.5rem;
			text-align: center;
			text-decoration: none;
			letter-spacing: 0.02em;
			line-height: 15px;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 25px 13px 30px; }
			
			&__text {
				&__en {
					display: block;
					color: #8590a4;
					font-size: $xs;
					padding-top: 5px;
				}
			}
			
			&::before {
				position: absolute;
				top: 5px;
				left: 0;
				content: '';
				height: 15px;
			}
			&::after {
				position: absolute;
				left: 50%;
				bottom: 0;
				z-index: 5;
				content: '';
				width: 0;
				height: 2px;
				background: #8590a4;
				backface-visibility: hidden;
				transform: translateX(-50%);
				@include transition;
			}
			
			&:hover {
				text-decoration: none;
				
				&::after {
					width: 100%;
				}
			}
		}
		&__icon {
			font-size: 3.0rem;
			color: #8590a4;
			padding: 20px 12px 0;
			text-decoration: none;
			&:hover {
				opacity: 0.7;
			}
		}
	}
}


/* .clone-header
----------------------------------------------------------- */
.clone-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
	background: $white;
  transform: translateY(-100%);
	@include transition;

	.gnav {
		&__item {
			padding: 25px 17px 27px;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 25px 13px 27px; }
		}
	}
}

.is-show {
  transform: translateY(0);
}
