@charset "utf-8";
/* ============================================================ */
/* item.html */
/* ============================================================ */
.item {
	.lineup {
		&__item {
			text-decoration: none;
			margin-bottom: 30px;
		}
	}
	
	img {
		height: auto;
	}
}

.item_lineup {
	&__price {
		border-top: 1px solid $baseColor;
		font-size: $xxxl;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		color: #4d4d4d;
		padding-top: 20px;
		margin-bottom: 40px;
	}
}