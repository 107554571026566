@charset "utf-8";
/* ============================================================ */
/* .contents */
/* ============================================================ */
.contents {
	padding-top: 110px;
	.inner {
		max-width: 1040px;
		margin: 0 auto;
		padding: 0px 20px 60px;
	}
}

/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */

.bg-gray {
	background: #eef0f3;
}

/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 15px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.indent {
	display: table;
	
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}

a[href^="tel:"] {
	cursor: default;
	text-decoration: none;
	&:hover {
		opacity: 1.0;
	}
}

/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.ttl01 {
	dmargin: 0 0 20px;
	color: #1a1a1a;
	font-size: 2.4rem;
	font-weight: bold;
	line-height: 1.3;
	height: 145px;
	padding-top: 50px;
	text-align: center;
	@include font-bold;
	position: relative;
	&::before {
		background: url(/common/img/common/bg_ttl.png);
		background-size: 302px 145px;
		position: absolute;
		content: '';
		display: block;
		width: 302px;
		height: 145px;
		left: 50%;
		margin-left: -200px;
		top: 0;		
	}
	
	&__en {
		color: #8590a4;
		font-size: 1.3rem;
		display: block;
		line-height: 2.0;
		@include font-nomal;
	}
}

.ttl02 {
	font-size: 2.8rem;
	@include font-bold;
	position: relative;
	margin-bottom: 20px;
	line-height: 1.3;
	padding-bottom: 10px;
	display: flex;
	align-items: center;
	&::after {
		position: absolute;
		left: 0;
		bottom: -1px;
		content: '';
		width: 45px;
		border-bottom: 1px solid #8590a4;

	}
	&__en {
		color: $baseColor;
		font-size: 1.7rem;
		@include font-nomal;
		margin-left: 20px;
	}
}

.ttl03 {
	dmargin: 0 0 20px;
	color: #1a1a1a;
	font-size: 2.4rem;
	font-weight: bold;
	line-height: 1.3;
	text-align: center;
	@include font-bold;
	position: relative;
	display: flex;
	align-items: center;
	&__en {
		color: #8590a4;
		font-size: 1.3rem;
		line-height: 2.0;
		@include font-nomal;
		margin-left: 10px;
	}
}

.ttl04 {
	position: relative;
	margin: 0 0 20px;
	padding: 0 0 15px;
	border-bottom: 1px solid $borderColor;
	font-size: $xxl;
	font-weight: bold;
	line-height: 1.4;
	
	&::after {
		position: absolute;
		left: 0;
		bottom: -1px;
		content: '';
		width: 115px;
		border-bottom: 1px solid $baseColor;
	}
}

.ttl05 {
	padding: 10px 20px;
	font-size: 1.7rem;
	height: 50px;
	background: #ced3e1;
	background: linear-gradient(to bottom, #ced3e1 0%,#ced3e1 97%,#ffffff 98%,#ffffff 100%);
	border-bottom: 1px solid #d1a027;
	@include font-bold;
}

.ttl06 {
	margin: 0 0 15px;
	font-size: 1.5rem;
	border-bottom: 1px solid $baseColor;
}

.ttl07 {
	font-size: 3.0rem;
	@include font-bold;
	text-align: center;
}


/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2 {
	 margin-left: -20px;
	 margin-right: -20px;

	 &> * {
		 margin-left: 20px;
		 margin-right: 20px;
		 width: calc((100% - 81px) / 2 );
	 }
 }

.column3 {
	margin-left: -10px;
	margin-right: -10px;

	&> * {
		margin-left: 10px;
		margin-right: 10px;
		width: calc((100% - 61px) / 3);
	}
}


/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;

	&> * {
		flex: 0 0 auto;

		* {
			max-width: 100%;
		}

	}
}


/* 画像回り込みカラム
------------------------------------------------------------ */
.float {

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			float: left;
			margin: 0 20px 20px 0;
			max-width: 50%;

			* {
				width: 100%;
			}
		}
	}

	&--right {
		.float__item {
			float: right;
			margin: 0 0 20px 20px;
			max-width: 50%;

			* {
				width: 100%;
			}

		}
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 15px;
		margin-bottom: 5px;

		&::before {
			content: "";
			width: 6px;
			height: 6px;
			display: block;
			position: absolute;
			top: 0.7em;
			left: 0;
		}
	}

	&--circle {
		& > * {
			&::before {
				width: 6px;
				height: 6px;
				border-radius: 100%;
			}
		}
	}
	
	&--black {
		& > * {
			&::before {
				background: $textColor;
			}
		}
	}

	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		& > * {
			padding-left: 0;
		}
	}
}


/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 25px;
	box-sizing: border-box;
	background: $white;

	&--gray {
		background: #f9f9f9;
	}

	&--border-gray {
		border: 1px solid $borderColor;
	}
	
	&--red {
		background: #fde9e9;
		border: 1px solid #f8cfd4;
	}
}


/* --------------------------------------------------- */
/* iframe */
/* --------------------------------------------------- */
.iframe {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;

	& > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
	
	th,
	td {
		padding: 15px;
		background: $white;
		border-top: 1px solid #ced3e1;
		border-bottom: 1px solid #ced3e1;
		text-align: left;
		vertical-align: middle;
		line-height: 1.6;
	}
	.bg-gray {
		background: #e4e8ef;
	}
	.error {
		background: #fde9e9;
		color: #dd0000;
	}	
}

.table--center {
	th,
	td {
		text-align: center;
	}
}

.table--thin {
	th,
	td {
		padding: 5px 10px;
		line-height: 1.4;
	}
}


/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 200px;
	height: 50px;
	padding: 0 30px;
	color: $white;
	font-size: $m;
	text-align: center;
	line-height: 1.4;
	cursor: pointer;
	transition: 0.3s all;
	text-decoration: none;
	@media screen and (min-width:  767.5px) and (max-width:1024px) { min-width: 150px; }

	.icon-chevron-right,
	.icon-chevron-down {
		right: 15px;
		@include centering-elements(false, true);
	}
	.icon-chevron-left,
	.icon-chevron-up {
		left: 15px;
		@include centering-elements(false, true);
	}
	&:hover {
		opacity: 0.7;
		text-decoration: none;
	}
	
	&--nomal {
		color: $textColor;
		border: 1px solid #b2b2b2;
		&:hover {
			border: 1px solid $textColor;
			background: $textColor;
			color: $white;
			opacity: 1.0;
		}		
	}
	&--black {
		background: $textColor;
		&:hover {
			border: 1px solid #b2b2b2;
			background: none;
			color: $textColor;
			opacity: 1.0;
		}	
	}
	&--lg {
		min-width: 300px;
		height: 60px;
		font-size: $m;
	}
}

button,
input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
	background: none;
}


/* --------------------------------------------------- */
/* pager */
/* --------------------------------------------------- */
.pager {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 30px 0 0;
	
	li {
		margin: 0 3px 5px;

		& > * {
			display: block;
			padding: 10px 15px;
			border: 1px solid #d5d5d5;
			color: $textColor;
			text-decoration: none;
			
			&.current,
			&:hover {
				background: #8590a4;
				border: 1px solid #8590a4;
				color: $white;
				opacity: 1;
				text-decoration: none;
			}
		}
	}
	
	&__prev {
		position: absolute;
		left: 0;
	}
	&__next {
		position: absolute;
		right: 0;
	}
}


/* --------------------------------------------------- */
/* accordion */
/* --------------------------------------------------- */
.accordion {
	position: relative;
	padding: 10px 30px 10px 10px;
	background: #f3f3f3;
	border: 1px solid #d5d5d5;
	cursor: pointer;
	line-height: 1.6;
	@include radius(3);
	
	&__icon {
		right: 10px;
		color: $baseColor;
		@include centering-elements(false, true);
	}
	&.active {
		.accordion__icon::before {
			display: block;
			transform: rotate(180deg);
		}
	}
}
.accordion-hide {
	display: none;
	padding: 15px 0 0;
}


/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.label-must {
	right: 10px;
	padding: 2px 10px;
	background: $textColor;
	color: $white;
	font-size: $xxs;
	@include centering-elements(false, true);
}

.error-block {
	margin-top: 5px;
	font-weight: bold;
}

input[type="text"] {
	appearance: none;
	height: 40px;
	padding: 0 10px;
	border: 1px solid #d2d2d2;
	color: $textColor;
	font-size: $m;
	@include radius(0);
}

textarea {
	appearance: none;
	padding: 5px 10px;
	width: 100%;
	height: 200px;
	border: 1px solid #d2d2d2;
	color: $textColor;
	@include radius(0);
}

select {
	height: 40px;
	padding: 0 0 0 10px;
	background: $white;
	border: 1px solid #d2d2d2;
}

/* --------------------------------------------------- */
/* news-list */
/* --------------------------------------------------- */
.news-list {
	margin-left: -20px;
	margin-right: -20px;
	font-size: 1.5rem;
	
	&__item {
		display: block;
		padding: 15px 0;
		margin-left: 20px;
		margin-right: 20px;
		width: calc((100% - 121px) / 3);
		text-decoration: none;
		
		img {
			height: auto;
			
			&:hover {
				opacity: 0.7;
			}
		}
		
		&__date {
			margin: 10px 0 5px;
			color: #8590a4;
			display: block;
		}
		&__link {
			text-decoration: none;

			.icon-filter_none {
				color: $baseColor;
			}
			
		}
	}
}


/* --------------------------------------------------- */
/* entry */
/* --------------------------------------------------- */
.entry {
	&__ttl {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin: 0 0 40px;
		border-bottom: 1px solid $baseColor;
		&__date {
			color: $baseColor;
		}
	}
	&__block {
		padding: 0 0 50px;
		display: flex;
		justify-content: space-between;
		flex-direction: row-reverse;
		width: 100%;
	}
}




/* ============================================================ */
/* .side */
/* ============================================================ */

/* --------------------------------------------------- */
/* snav */
/* --------------------------------------------------- */
.snav {
	&__ttl {
		&__link {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 120px;
			padding: 5px 0 0;
			background: $textColor;
			color: $white;
			font-size: $l;
			text-align: center;
			
			@media screen and (min-width:  767.5px) and (max-width:1024px) { height: 11.8vh; }

			&::before {
				top: 0;
				content: '';
				width: 50px;
				height: 5px;
				background: $baseColor;
				@include centering-elements(true, false);
			}
		}
	}
	&__item {
		display: flex;
		align-items: center;
		padding: 20px 15px;
		border: 1px solid $borderColor;
		border-top: none;

		&__text {
			flex: 1 1 auto;
			font-size: $s;
		}
		.icon-angle-right {
			flex: 0 0 auto;
			margin: 0 0 0 10px;
			font-size: $xxs;
		}
		&:hover {
			opacity: 1;
			background: #f0f0f0;
		}
	}
}

/* ============================================================ */
/* .recommend */
/* ============================================================ */
.recommend {
	&__item {
		text-decoration: none;
		
		&:hover img {
			opacity: 0.7;
			@include transition;
		}

		img {
			height: auto;
		}
	}
}
