@charset "utf-8";
/* ============================================================ */
/* about.html */
/* ============================================================ */
.about {
	.inner {
		padding: 20px 15px 20px;
	}
	.concept {
		&__item {
			&__text {
				text-align: center;
				.ttl02 {
					display: block;
					&::after {
						left: 50%;
						margin-left: -22px;
					}
				}
			}
		}
	}
}