@charset "utf-8";
/* ============================================================ */
/* company.html */
/* ============================================================ */
.company {
	.principle {
		&__name {
			font-size: 2.8rem;
			border-bottom: 1px solid $baseColor;
			display: flex;
			align-items: center;
			margin-bottom: 20px;
		}
		&__banner {
			border: 1px solid $baseColor;
			padding: 10px 50px 10px 20px;
			max-width: 300px;
			position: relative;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			transition: 0.3s all;
			text-decoration: none;

			.icon-chevron-right {
				right: 15px;
				@include centering-elements(false, true);
			}
			
			&:hover {
				opacity: 0.7;
			}
		}
	}
}