@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	position: relative;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	width: 100%;
	height: 60px;
	background: $white;
	border-bottom: 1px solid $borderColor;
	.header-wrap {

	}	
	.header-inner {
		position: relative;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 0 0 15px;		
	}

	.header-logo {
		width: 100px;
	}
	.header-menu {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 60px;
		height: 60px;
		background: none;
		color: $baseColor;
		font-size: 3.2rem;
	}
	.header-icon {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 60px;
		color: $baseColor;
		font-size: 3.2rem;		
	}
	
	.gnav {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		padding: 60px 0 15px;
		background: rgba(#000, 0.80);
		overflow-y: scroll;
		visibility: hidden;
		opacity: 0;
		transform: translateY(-100%);
		@include transition;
		
		&.open {
			transform: translateY(0);
			visibility: visible;
			opacity: 1;
			@include transition;
		}
		
		&__inner {
			display: flex;
			flex-wrap: wrap;
			margin: 0 0 25px;
			border-top: 1px solid $borderColor;
		}
		
		&__item {
			width: 100%;
			padding: 15px;
			border-bottom: 1px solid $borderColor;
			text-decoration: none;
			color: #e5e5e5;
			
			&:nth-child(2n+1) {
				border-right: 1px solid $borderColor;
			}
			
			.icon-angle-right {
				margin: 0 10px 0 0;
				color: #e5e5e5;
			}
		}
		
		.gnav__phone {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 0 10px;
			font-size: 2.8rem;
			line-height: 1;
			letter-spacing: 0;
			@include font-bold;

			.icon-phone {
				margin: 3px 5px 0 0;
				color: $baseColor;
				font-size: $xxxl;
			}
		}
		
		
		&__close {
			display: inline-flex;
			flex-direction: column;
			align-items: center;
			color: $baseColor;
			font-size: $xxs;
			width: 100%;
			
			.icon-close {
				font-size: 3.2rem;
			}
		}
	}
}


/* .clone-header
----------------------------------------------------------- */
.clone-header {
	display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
	background: rgba(#000, 0.80);
  transform: translateY(-100%);
	@include transition;
	.header-menu {
		color: $white;
	}
	.header-icon {
		color: $white;
	}
	.gnav.open {
		visibility: hidden;
		opacity: 0;
	}
	
	&.is-show {
		transform: translateY(0);
	}
}

